<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner title="CLIENT PROFILE" :breadcrumb="[
           {
              label: 'Dashboard',
            },
            {
              label: 'Clients',
            },
          { label: 'Client Profile' }
          ]">
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-5 col-md-5 col-sm-5" style="padding: 5px 5px 5px 5px">
            <div
              class="card custom-card"
              style="
             
                height: 100%;
              "
            >
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder">Client Profile</b>
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder">Client Profile</b>
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <!-- img responsive mobile  -->
                  <div
                    class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1"
                  >
                    <img
                      style="width: 40%; border-radius: 10px"
                      :src="profileImg"
                      alt=""
                    />
                    <br />
                    <span style="color: #00364f">
                      <p
                        v-if="client.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                  <h5 class="text-start">
                    <b style="color: #f21300; font-weight: bolder">Personal Details </b>
                  </h5>

                  <div class="col-xl-9 col-md-9 col-lg-9 col-sm-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Code : </b>
                      <span v-if="client">{{ client.code }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Name : </b>
                      <span v-if="client">{{ client.fname }} {{ client.lname }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>DOB : </b>
                      <span v-if="client"> {{ dateTime(client.dob) }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Phone : </b>
                      <span v-if="client">{{ client.phone }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>E-mail : </b>
                      <span v-if="client">{{ client.email }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Gender : </b>
                      <span v-if="client">{{ client.gender }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />

                      <b>Address : </b>
                      <span v-if="client">{{ client.address }} {{ client.city }} <span v-if="client.state">{{ client.state.name }}</span> {{ client.pin }}</span>
                    </div>
                    <!-- <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>State : </b>
                      <span v-if="client.state">{{ client.state.name }}</span>
                    </div> -->
                  </div>
                  <!-- img responsive Pc  -->
                  <div
                    class="col-xl-3 col-md-3 col-lg-3 d-none d-xl-block d-md-block d-lg-block text-center"
                  >
                    <img
                      style="width: 100%; border-radius: 10px; margin-top: -20px"
                      :src="profileImg"
                      alt=""
                    />
                    <span style="color: #00364f; width: 40%">
                      <!-- v-if="superadmin.status == 'true'" -->
                      <p
                        v-if="client.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                </div>

                <div class="row">
                  <h5 class="text-start mt-2">
                    <b style="color: #f21300; font-weight: bolder">Client Kyc </b>
                  </h5>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Aadhar Number : </b>
                     
                      <span v-if="client.client_kyc">{{ client.client_kyc.aadharNo }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                    <span>
                      <b>Pan Number : </b>
                      <span style="text-transform: uppercase" v-if="client.client_kyc">{{ client.client_kyc.panNo }}</span>
                   
                     </span>

                   </div>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-12 text-end">
                    <button
                      type="button"
                      class="btn text-white btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#doccuments"
                      style="background-color: #f21300; margin-right: 5px"
                    >
                      <b
                        >Kyc Doccuments &nbsp;
                        <i class="fa fa-hand-pointer-o"></i>
                      </b>
                    </button>
                  </div>
                </div>
                <div
                  class="row mt-1"
                  style="background-color: #e3e3e3; border-radius: 15px"
                >
                  <div class="col-12 p-1">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>IP Address :</b>
                      <span v-if="client">{{ client.ipAddress }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Last Login :</b>
                      <span v-if="client">{{ dateTimeWithTime(client.last_login) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-7 col-md-7 col-sm-7" style="padding: 5px 5px 5px 5px">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
              style=" height: 100%"
            >
              <div class="card-body">
                <!-- start list area  -->
                <div class="row">
                  <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-start">
                    <h4><b style="color: #00364f; font-weight: bolder">Project</b></h4>
                  </div>
                  <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-end"></div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th class="text-truncate">Project ID</th>
                          <th class="text-truncate">	Date</th>
                          <th class="text-truncate">Client Name</th>
                          <th class="text-truncate">Retailer Name</th>
                          <th class="text-truncate">	Business</th>
                          <th class="text-truncate">Service	</th>
                          <th class="text-truncate">Phone</th>
                          <th class="text-truncate">Invoice</th>
                          <th class="text-truncate">Due Balance</th>
                          <th class="text-truncate text-center">Status</th>
                          <th class="text-truncate">Action</th>
                        </tr>

                        <tbody class="text">
                          <tr v-for="(client_service, index) in projects" :key="index">
                            <td class="text-truncate">
                          <span v-if="client_service">{{
                            client_service.clientServiceNo
                          }}</span>
                        </td>
                        <td class="text-truncate">
                          <span v-if="client_service">{{
                            dateTime(client_service.date)
                          }}</span>
                        </td>
                        <td class="text-truncate">
                          <span v-if="client_service.client">
                            {{ client_service.client.fname }}
                            {{ client_service.client.lname }}</span
                          >
                        </td>
                        <td class="text-truncate">
                          <span v-if="client_service.retailer.name">
                            {{ client_service.retailer.name }}
                            </span
                          >
                        </td>
                        <td class="text-truncate">
                          <span v-if="client_service.business">
                            <!-- {{ client_service.service.name }} -->
                            {{ client_service.business.name }}
                          </span>
                        </td>
                        <td class="text-truncate">
                          <span v-if="client_service.service">
                            {{ client_service.service.name }}
                          </span>
                        </td>

                        <td class="text-truncate">
                          <span v-if="client_service.client">
                            +91-{{ client_service.client.phone }}
                          </span>
                        </td>

                        <td class="text-truncate text-end">
                          <span v-if="client_service">{{
                            client_service.serviceCharge +
                            client_service.serviceChargeGst
                          }}</span>
                        </td>
                        <td class="text-truncate text-end">
                          <span v-if="client_service">{{
                            getBallance(client_service)
                          }}</span>
                        </td>
                    
                        <td
                          v-if="client_service.client"
                          class="text-truncate"
                          style="text-transform: capitalize"
                        >
                          <p
                            v-if="client_service.status == 'assigned'"
                            class="btn btn-sm"
                            style="
                              width: 60px;
                              background-color: #00364f;
                              color: white;
                              box-shadow: 0px 0px 5px 0px gray;
                              border-radius: 50px;
                              font-size: 10px;
                              padding: 3px 3px 3px 3px;
                              margin-top: 5px;
                              margin-bottom: 5px;
                            "
                          >
                            <b>{{ client_service.status }}</b>
                          </p>
                          <p
                            v-if="client_service.status == 'completed'"
                            class="btn btn-sm"
                            style="
                              width: 90px;
                              background-color: #008000;
                              color: white;
                              box-shadow: 0px 0px 5px 0px gray;
                              border-radius: 50px;
                              font-size: 10px;
                              padding: 3px 3px 3px 3px;
                              margin-top: 5px;
                              margin-bottom: 5px;
                            "
                          >
                            <b>{{ client_service.status }}</b>
                          </p>

                          <p
                            v-if="client_service.status == 'pending'"
                            class="btn btn-sm"
                            style="
                              width: 60px;
                              background-color: #cdcdcd;
                              color: white;
                              border-radius: 50px;
                              font-size: 10px;
                              padding: 3px 3px 3px 3px;
                              margin-top: 5px;
                              margin-bottom: 5px;
                            "
                          >
                            <b>New</b>
                          </p>
                          <p
                            v-if="client_service.status == 'processed'"
                            class="btn btn-sm"
                            style="
                              width: 80px;
                              background-color: #ffaa00;
                              color: white;
                              box-shadow: 0px 0px 5px 0px gray;
                              border-radius: 50px;
                              font-size: 10px;
                              padding: 3px 3px 3px 3px;
                              margin-top: 5px;
                              margin-bottom: 5px;
                            "
                          >
                            <b>{{ client_service.status }}</b>
                          </p>
                          <p
                            v-if="client_service.status == 'hold'"
                            class="btn btn-sm"
                            style="
                              width: 60px;
                              background-color: #8f8d8e;
                              color: white;
                              box-shadow: 0px 0px 5px 0px gray;
                              border-radius: 50px;
                              font-size: 10px;
                              padding: 3px 3px 3px 3px;
                              margin-top: 5px;
                              margin-bottom: 5px;
                            "
                          >
                            <b>{{ client_service.status }}</b>
                          </p>
                          <p
                            v-if="client_service.status == 'rejected'"
                            class="btn btn-sm"
                            style="
                              width: 60px;
                              background-color: #00364f;
                              color: white;
                              box-shadow: 0px 0px 5px 0px gray;
                              border-radius: 50px;
                              font-size: 10px;
                              padding: 3px 3px 3px 3px;
                              margin-top: 5px;
                              margin-bottom: 5px;
                            "
                          >
                            <b>{{ client_service.status }}</b>
                          </p>
                          <p
                            v-if="client_service.status == 'cancelled'"
                            class="btn btn-sm"
                            style="
                              width: 80px;
                              background-color: #f21000;
                              color: white;
                              box-shadow: 0px 0px 5px 0px gray;
                              border-radius: 50px;
                              font-size: 10px;
                              padding: 3px 3px 3px 3px;
                              margin-top: 5px;
                              margin-bottom: 5px;
                            "
                          >
                            <b>{{ client_service.status }}</b>
                          </p>
                          <p
                            v-if="client_service.status == 'upgrade'"
                            class="btn btn-sm"
                            style="
                              width: 60px;
                              background-color: #ee0def;
                              color: white;
                              box-shadow: 0px 0px 5px 0px gray;
                              border-radius: 50px;
                              font-size: 10px;
                              padding: 3px 3px 3px 3px;
                              margin-top: 5px;
                              margin-bottom: 5px;
                            "
                          >
                            <b>{{ client_service.status }}</b>
                          </p>
                        </td>

                            <td class="text-end">
                              <div
                                class="btn-group btn-group-sm"
                                role="group"
                                aria-label="..."
                              >
                                <router-link
                                  :to="`/super-admin/project-view/${client_service.id}`"
                                  class="btn btn-sm text-white"
                                  style="
                                    width: 25px;
                                    padding: 5px 5px 5px 5px;
                                    background-color: #00364f;
                                    font-size: 8pt;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>

                                <!-- <router-link
                                  to="#"
                                  style="
                                    width: 25px;
                                    padding: 5px 5px 5px 5px;
                                    background-color: red;
                                    font-size: 8pt;
                                  "
                                  @click="cancelProject(client_service.id)"
                                  type="button"
                                  v-if="
                                    client_service.status != 'cancelled' &&
                                    client_service.status == 'pending'
                                  "
                                  class="btn btn-sm text-white"
                                >
                                  <font-awesome-icon icon="remove" />
                                </router-link> -->
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="d-block d-md-none d-lg-none mt-2"></div>
          <div class="col-xl-12 col-md-12 col-lg-12 col-12" style="padding: 5px 5px 5px 5px">
            <div
              class="card custom-card"
              style="
              
                height: 100%;
              "
            >
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-xl-12 col-md-12 col-lg-12 col-sm-12 col-12 text-start"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <h4><b style="color: #00364f; font-weight: bolder">Business</b></h4>
                  </div>
                  <!-- <div
                    class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-end"
                    @click="valuenullOfModalPanDissable()"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <button
                      @click="businessNull()"
                      type="button"
                      class="btn btn-sm text-white"
                      style="background-color: #f21300"
                      data-bs-toggle="modal"
                      data-bs-target="#createBussiness"
                    >
                      <strong>+ New</strong>
                    </button>
                  </div> -->

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th class="text-truncate">Business Name</th>
                          <th class="text-truncate">Type</th>
                          <th class="text-truncate">Pan</th>
                          <th class="text-truncate">Users</th>
                          <th class="text-truncate">Email</th>
                          <th class="text-truncate">Phone</th>
                          <!-- <th class="text-truncate">Action</th> -->
                        </tr>

                        <tbody class="text">
                          <tr v-for="(bgs, index) in businessAll" :key="index">
                            <td class="text-truncate" style="text-transform: uppercase">
                              <span v-if="bgs.business">{{ bgs.business.name }}</span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="bgs.business.business_type">{{
                                bgs.business.business_type.type
                              }}</span>
                            </td>
                            <td class="text-truncate" style="text-transform: uppercase">
                              <span v-if="bgs.business">{{ bgs.business.panNo }}</span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="bgs.business">{{
                                bgs.business.business_clients.length
                              }}</span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="bgs.business">{{ bgs.business.email }}</span>
                            </td>
                            <td class="text-truncate">
                              <span v-if="bgs.business">{{ bgs.business.phone }}</span>
                            </td>

                            <!-- <td class="text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                  @click="valusigetByClick(bgs.business)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#addMembersBussiness"
                                  type="button"
                                  class="btn btn-sm text-white"
                                  style="
                                    width: 25px;
                                    background-color: #f21300;
                                    padding: 5px 5px 5px 5px;
                                    font-size: 8pt;
                                  "
                                >
                                  <strong>+</strong>
                                </button>
                                <button
                                  @click="assignBussinessId(bgs.business)"
                                  type="button"
                                  class="btn btn-sm text-white"
                                  style="
                                    width: 25px;
                                    font-size: 8pt;
                                    background-color: blue;
                                    padding: 5px 5px 5px 5px;
                                  "
                                  data-bs-toggle="modal"
                                  data-bs-target="#doccUpload"
                                >
                                  <i class="fa fa-upload" aria-hidden="true"></i>
                                </button>
                                <router-link
                                  @click="getBusinessInformation(bgs.business)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#informationBusiness"
                                  to="#"
                                  type="button"
                                  class="btn btn-sm text-white"
                                  style="
                                  font-size:8pt
                                  width:25px;
                                    background-color: #00364f;
                                    padding: 5px 5px 5px 5px;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>
                                <button
                                  @click="valueAssignBussiness(bgs.business)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#createBussiness"
                                  type="button"
                                  class="btn btns btn-sm btn-success"
                                  style="
                                    width: 25px;
                                    font-size: 8pt;
                                    padding: 5px 5px 5px 5px;
                                  "
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>

                              </div>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="informationBusiness"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel">
            <strong>Business Information</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text">
          <div class="row">
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-12 text-center d-block d-xl-none d-lg-none d-md-none p-1"
            >
              <img style="width: 100%" :src="logoReturn(businessInformation)" alt="" />
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-12">
              <!-- <div><strong>Business Name : </strong><span>{{businessInformation.name}}</span></div> -->
              <div>
                <strong>Type : </strong
                ><span v-if="businessInformation.business_type">{{
                  businessInformation.business_type.type
                }}</span>
              </div>
              <div>
                <strong>Email: </strong><span>{{ businessInformation.email }}</span>
              </div>
              <div>
                <strong>Phone : </strong><span>{{ businessInformation.phone }}</span>
              </div>
              <div>
                <strong>PAN: </strong><span>{{ businessInformation.panNo }}</span>
              </div>
              <div>
                <strong>TAN : </strong><span>{{ businessInformation.tanNo }}</span>
              </div>
              <div>
                <strong>GSTIN: </strong><span>{{ businessInformation.gstinNo }}</span>
              </div>
              <div>
                <strong>Address : </strong><span>{{ businessInformation.address }}</span>
              </div>
              <div>
                <strong>Address 2: </strong
                ><span>{{ businessInformation.address2 }}</span>
              </div>

              <div>
                <strong>State : </strong
                ><span v-if="businessInformation.state">{{
                  businessInformation.state.name
                }}</span>
              </div>
              <div>
                <strong>City : </strong><span>{{ businessInformation.city }}</span>
              </div>
              <div>
                <strong>Pin : </strong><span>{{ businessInformation.pin }}</span>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-12 text-center d-none d-xl-block d-lg-block d-md-block p-1"
            >
              <img
                style="width: 100%; height: 200px"
                :src="logoReturn(businessInformation)"
                alt=""
              />
            </div>
          </div>

          <div class="row pt-1">
            <div class="col-xl-12 col-md-12 col-12">
              <div class="container-fluid table-scroll">
                <table class="table table-hover table-sm">
                  <tr
                    class="text-white table-wrapper-scroll-y rounded-circle"
                    style="background-color: #00364f"
                  >
                    <th class="text-truncate">+</th>
                    <th class="text-truncate">Name</th>
                    <th class="text-truncate">Email</th>
                    <th class="text-truncate">Phone</th>
                    <th class="text-truncate">Pan</th>
                    <th class="text-truncate">Status</th>
                  </tr>
                  <tbody v-if="businessInformation.business_clients">
                    <!-- v-for="(client, index) in businessInformation.business_clients"
                                                        :key="index" -->
                    <tr
                      style="color: #00364f"
                      v-for="(bsclient, index) in businessInformation.business_clients"
                      :key="index"
                    >
                      <td class="text-truncate">
                        <img
                          style="width: 8px; margin-right: 5px"
                          src="/assets/image/record.png"
                          alt=""
                        />
                      </td>

                      <td class="text-truncate" v-if="bsclient.client">
                        <a :href="`/superadmin/clientprofile/${bsclient.client.id}`"
                          >{{ bsclient.client.fname }} {{ bsclient.client.lname }}
                          <!-- </router-link> -->
                        </a>
                      </td>
                      <td class="text-truncate">
                        <span v-if="bsclient.client">{{ bsclient.client.email }}</span>
                      </td>
                      <td class="text-truncate">
                        <span v-if="bsclient.client">{{ bsclient.client.phone }}</span>
                      </td>
                      <td class="text-truncate">
                        <span v-if="bsclient.client"> {{ bsclient.client.pan }}</span>
                      </td>
                      <td class="text-truncate">
                        <p
                          v-if="bsclient.status == 'true'"
                          class="text-white btn btn-sm"
                          style="
                            background-color: green;
                            width: 70px;
                            box-shadow: 2px 2px 5px gray;
                            border-radius: 50px;
                            font-size: 10px;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-left: 7px;
                            padding-right: 7px;
                            margin-top: 5px;
                            margin-bottom: 5px;
                          "
                        >
                          <b> Active</b>
                        </p>
                        <p
                          v-else
                          class="text-white btn btn-sm"
                          style="
                            background-color: red;
                            width: 70px;
                            box-shadow: 2px 2px 5px gray;
                            border-radius: 50px;
                            font-size: 10px;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-left: 7px;
                            padding-right: 7px;
                            margin-top: 5px;
                            margin-bottom: 5px;
                          "
                        >
                          <b> InActive</b>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #f21300"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <!-- <button type="button" class="btn btn-sm">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Modal members add  -->
  <div
    class="modal fade"
    id="addMembersBussiness"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add User</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="edit == 'false' ? addNewClient() : updateClient()"
            id="formId"
          >
            <div class="row">
              <div class="col-xl-7 col-lg-7 col-md-7 col-12">
                <div class="row">
                  <small v-if="veryfied == 'invalid'" class="text-danger"
                    >X <i>Please Enter Valid Pan</i></small
                  >
                  <small v-if="veryfied == 'false'"></small>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Enter Pan</label>
                    <input
                      v-if="panDisabled == 'false'"
                      type="tel"
                      maxlength="10"
                      @change="findRecordeByPanId"
                      v-model="panNo"
                      class="form-control"
                      placeholder="Pan"
                      style="text-transform: uppercase"
                    />

                    <input
                      v-if="panDisabled == 'true'"
                      type="tel"
                      maxlength="10"
                      v-model="form.pan"
                      class="form-control"
                      placeholder=" Pan"
                      style="text-transform: uppercase"
                      disabled
                    />
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Mobile No.</label>
                    <input
                      type="number"
                      maxlength="10"
                      v-model="form.phone"
                      class="form-control"
                      placeholder="Mobile Number"
                      @change="lenthheker($event, 'demophoneval')"
                    />
                    <span style="color: #f21000" id="demophoneval"></span>
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>First Name</label>
                    <input
                      type="text"
                      v-model="form.fname"
                      class="form-control"
                      placeholder="First name"
                      aria-label="First name"
                      style="text-transform: uppercase"
                    />
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Last Name</label>
                    <input
                      type="text"
                      v-model="form.lname"
                      class="form-control"
                      placeholder="Last name"
                      aria-label="Last name"
                      style="text-transform: uppercase"
                    />
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Date Of Birth</label>
                    <input
                      type="date"
                      v-model="form.dob"
                      class="form-control"
                      placeholder="DOB"
                      style="text-transform: uppercase"
                      @change="dateCheker($event, 'doBDateAdd')"
                    />
                    <span style="color: #f21000" id="doBDateAdd"></span>
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Gender</label>
                    <select
                      id="inputState"
                      v-model="form.gender"
                      class="form-select"
                      style="text-transform: uppercase"
                    >
                      <option selected value="male">Male</option>
                      <option value="female">FeMale</option>
                      <option value="female">Other</option>
                    </select>
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Email</label>
                    <input
                      type="email"
                      class="form-control"
                      v-model="form.email"
                      placeholder="E-Mail"
                      style="text-transform: uppercase"
                      required
                    />
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Alternate Number</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="form.alternativePhone"
                      placeholder="Alternate Number"
                    />
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-5 col-12 border-3 border-start">
                <div class="row">
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Address line 1</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.address"
                      placeholder="Address Line-1"
                      style="text-transform: uppercase"
                      @change="lenthheker($event, 'adminAddress')"
                    />
                    <span style="color: #f21000" id="adminAddress"></span>
                  </div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Address line 2</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.address2"
                      placeholder="Address Line-2"
                      style="text-transform: uppercase"
                    />
                  </div>

                  <div
                    class="col-xl-4 col-lg-4 col-md-4 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>State</label>
                    <select id="inputState" v-model="form.state_id" class="form-select">
                      <option
                        v-for="(state, index) in states"
                        :key="index"
                        :value="state.id"
                      >
                        {{ state.name }}
                      </option>
                    </select>
                  </div>

                  <div
                    class="col-xl-4 col-lg-4 col-md-4 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>City</label>
                    <input
                      type="text"
                      v-model="form.city"
                      class="form-control"
                      id="inputCity"
                      placeholder="City"
                      style="text-transform: uppercase"
                      @change="lenthheker($event, 'adminCity')"
                    />
                    <span style="color: #f21000" id="adminCity"></span>
                  </div>

                  <div
                    class="col-xl-4 col-lg-4 col-md-4 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Pin Code</label>
                    <span style="color: #f21000" id="pinCode"></span>
                    <input
                      type="number"
                      maxlength="6"
                      v-model="form.pin"
                      min="1"
                      class="form-control"
                      id="inputZip"
                      placeholder="Pin"
                      @change="lenthheker($event, 'pinCode')"
                    />
                    <span style="color: #f21000" id="pinCode"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer" style="margin-top: 10px">
              <button
                data-bs-dismiss="modal"
                v-if="panDisabled == 'true'"
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <b> <font-awesome-icon icon="check" /> Confirm </b>
              </button>

              <!-- <button type="reset" class="btn btn-sm text-white" style="background-color:red; "><b>
                                    <font-awesome-icon icon="remove" />
                                </b> Reset</button> -->
              <button
                type="button"
                class="btn btn-danger text-white btn-sm"
                data-bs-dismiss="modal"
                style="background-color: red"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="addBank"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel">
            <strong>Add Bank Account</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="form-group">
                <label>Account Holder</label>
                <input
                  v-model="form_bank.accountHolderName"
                  type="text"
                  style="text-transform: uppercase"
                  class="form-control"
                  placeholder="Account Holder Name"
                />
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="form-group">
                <label>Bank</label>
                <input
                  v-model="form_bank.bank"
                  type="text"
                  style="text-transform: uppercase"
                  class="form-control"
                  placeholder="Bank Name"
                />
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="form-group">
                <label>Branch</label>
                <input
                  type="text"
                  v-model="form_bank.branch"
                  class="form-control"
                  placeholder="Bank Branch"
                  style="text-transform: uppercase"
                />
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="form-group">
                <label>Account Number</label>
                <input
                  type="text"
                  v-model="form_bank.accountNo"
                  style="text-transform: uppercase"
                  class="form-control"
                  placeholder="Account Number"
                />
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="form-group">
                <label>Ifsc </label>
                <input
                  v-model="form_bank.ifsc"
                  type="text"
                  class="form-control"
                  style="text-transform: uppercase"
                  placeholder="Ifsc"
                />
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="form-group">
                <label>Type</label>
                <input
                  type="text"
                  v-model="form_bank.type"
                  class="form-control"
                  style="text-transform: uppercase"
                  placeholder="Type"
                />
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="form-group">
                <label>Mobile Number</label>
                <input
                  type="number"
                  maxlength="10"
                  v-model="form_bank.mobile"
                  class="form-control"
                  style="text-transform: uppercase"
                  placeholder="Mobile Number"
                />
              </div>
            </div>

            <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="form-group">
                <label>Status</label>
                <!-- <input type="text" class="form-control" placeholder="Bank Name"> -->
                <select
                  v-model="form_bank.status"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Open this select menu</option>
                  <option value="true">ACTIVE</option>
                  <option value="false">INACTIVE</option>
                </select>
              </div>
            </div>

            <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
              <div class="form-group">
                <label>Remark</label>
                <textarea
                  v-model="form_bank.remark"
                  rows="3"
                  class="form-control"
                  placeholder="Remark"
                  style="text-transform: uppercase"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #f21300"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <!-- @click="(businessVal == 'false') ? createBussinessSave() : updateBussinessSave()" -->
          <button
            @click="createBankForBussiness"
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #00364f"
          >
            <strong>Confirm</strong>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal kyc doccuments show  -->
  <div
    class="modal fade"
    id="doccuments"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Kyc Doccuments</b></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm">
              <tr
                class="table-wrapper-scroll-y text-white rounded-circle"
                style="background-color: #00364f; height: 40px"
              >
                <th>+</th>
                <th>Name</th>
                <th>Action</th>
              </tr>

              <tbody>
                <tr style="color: #00364f">
                  <td>
                    <img
                      style="width: 30px; height: 30px; border-radius: 2px"
                      :src="adharFrontImg"
                      alt=""
                    />
                  </td>

                  <td><p>Aadhar Front</p></td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <!-- <a
                        :href="adharFrontImg"
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                        download
                        target="_blank"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </a> -->
                      <button
                        @click="
                          extensionkycAdharFront == 'pdf'
                            ? openWindow(adharFrontImgPdf)
                            : openWindow(adharFrontImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img
                      style="width: 30px; height: 30px; border-radius: 2px"
                      :src="adharBackImg"
                      alt=""
                    />
                  </td>

                  <td>Aadhar Back</td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharBack == 'pdf'
                            ? openWindow(adharBackImgPdf)
                            : openWindow(adharBackImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img
                      style="width: 30px; height: 30px; border-radius: 2px"
                      :src="panImg"
                      alt=""
                    />
                  </td>

                  <td>
                    <p>Pan</p>
                  </td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycPanPhoto == 'pdf'
                            ? openWindow(panImgPdf)
                            : openWindow(panImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- </form> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn text-white btn-sm"
            data-bs-dismiss="modal"
            style="background-color: #f21300"
          >
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>

  <!-- wallet superadmin -->

  <!--start the bussiness  Modal -->
  <div
    class="modal fade"
    id="createBussiness"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel">
            <strong>Bussiness Create</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="
              businessVal == 'false' ? createBussinessSave() : updateBussinessSave()
            "
          >
            <div class="row">
              <small v-if="veryfied == 'invalid'" class="text-danger"
                >X <i>Please Enter Valid Pan</i></small
              >
              <small v-if="veryfied == 'false'"></small>
              <div
                class="col-xl-4 col-md-4 col-lg-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Enter Pan</label>
                <input
                  v-if="panDisabled == 'false'"
                  type="tel"
                  @change="businessPanVeryfied"
                  v-model="panNo"
                  class="form-control"
                  placeholder="Pan"
                  style="text-transform: uppercase"
                  required
                />

                <input
                  required
                  v-if="panDisabled == 'true'"
                  type="tel"
                  @change="businessPanVeryfied"
                  v-model="bussiness_form.panNo"
                  class="form-control"
                  placeholder="Pan"
                  style="text-transform: uppercase"
                  disabled
                />

                <small style="color: red" v-if="bussiness_form.errors">
                  <span v-if="bussiness_form.errors.error"
                    ><span v-if="bussiness_form.errors.error.pan">{{
                      bussiness_form.errors.error.pan[0]
                    }}</span></span
                  ></small
                >
              </div>

              <div
                class="col-xl-4 col-md-4 col-lg-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label for="name">Business Type</label>
                <select
                  style="text-transform: uppercase"
                  v-model="bussiness_form.business_type_id"
                  required
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Bussiness Types...</option>
                  <option
                    :value="type.id"
                    v-for="(type, index) in businesstype"
                    :key="index"
                  >
                    {{ type.type }}
                  </option>
                </select>
              </div>
              <div
                class="col-xl-4 col-md-4 col-lg-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label for="name">Business Name</label>
                <input
                  type="text "
                  v-model="bussiness_form.name"
                  class="form-control"
                  placeholder="Business Name"
                  style="text-transform: uppercase"
                  required
                  @change="lenthheker($event, 'bussinessName')"
                />
                <span style="color: #f21000" id="bussinessName"></span>
              </div>

              <div
                class="col-xl-4 col-md-4 col-lg-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label for="name">CIN/LLPIN/Reg No.</label>
                <input
                  type="text"
                  v-model="bussiness_form.cinNo"
                  class="form-control"
                  placeholder="CinNo"
                  style="text-transform: uppercase"
                />
              </div>

              <div
                class="col-xl-4 col-md-4 col-lg-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label for="name">GST No.</label>
                <input
                  type="text"
                  v-model="bussiness_form.gstinNo"
                  class="form-control"
                  placeholder="GstIn"
                  style="text-transform: uppercase"
                  @change="lenthheker($event, 'gstinVal')"
                />
                <span style="color: #f21000" id="gstinVal"></span>
              </div>

              <div
                class="col-xl-4 col-md-4 col-lg-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label for="name">TAN</label>
                <input
                  type="text"
                  v-model="bussiness_form.tanNo"
                  class="form-control"
                  placeholder="Tan No"
                  style="text-transform: uppercase"
                />
              </div>
              <div
                class="col-xl-4 col-md-4 col-lg-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label for="email">Email</label>
                <input
                  type="email "
                  v-model="bussiness_form.email"
                  class="form-control"
                  placeholder="email"
                  style="text-transform: uppercase"
                  required
                />

                <small style="color: red" v-if="bussiness_form.errors">
                  <span v-if="bussiness_form.errors.error"
                    ><span v-if="bussiness_form.errors.error.email">{{
                      bussiness_form.errors.error.email[0]
                    }}</span></span
                  ></small
                >

                <!-- <Field class="form-control" v-model="bussiness_form.email"  placeholder="Email Or UserId" name="email" />
    <span v-if="errors.email">⛔️ {{ errors.email }} please enter valid email</span> -->
              </div>
              <div
                class="col-xl-4 col-md-4 col-lg-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label for="phone">Phone</label>
                <input
                  type="number"
                  maxlength="10"
                  v-model="bussiness_form.phone"
                  class="form-control"
                  placeholder="phone"
                  style="text-transform: uppercase"
                  required
                  @change="lenthheker($event, 'demophonevalBUS')"
                />
                <span style="color: #f21000" id="demophonevalBUS"></span>
              </div>

              <small style="color: red" v-if="bussiness_form.errors">
                <span v-if="bussiness_form.errors.error"
                  ><span v-if="bussiness_form.errors.error.phone">{{
                    bussiness_form.errors.error.phone[0]
                  }}</span></span
                ></small
              >
              <div
                class="col-xl-4 col-md-4 col-lg-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label for="name">Address</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="bussiness_form.address"
                  placeholder="Address"
                  style="text-transform: uppercase"
                  required
                  @change="lenthheker($event, 'adminAddressBUS')"
                />
                <span style="color: #f21000" id="adminAddressBUS"></span>
              </div>
              <div
                class="col-xl-4 col-md-4 col-lg-4 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label for="Address">Address 2</label>
                <input
                  type="text "
                  class="form-control"
                  v-model="bussiness_form.address2"
                  placeholder="Address"
                  style="text-transform: uppercase"
                />
              </div>
              <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>State</label>
                <!-- <input type="text " class="form-control" placeholder="Name" -->
                <!-- style="text-transform: uppercase"> -->
                <select
                  v-model="bussiness_form.state_id"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Open this select menu</option>
                  <option :value="state.id" v-for="(state, index) in states" :key="index">
                    {{ state.name }}
                  </option>
                </select>
              </div>
              <div
                class="col-xl-3 col-md-3 col-lg-3 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>City</label>
                <input
                  type="text "
                  v-model="bussiness_form.city"
                  class="form-control"
                  placeholder="City"
                  style="text-transform: uppercase"
                  @change="lenthheker($event, 'adminCity')"
                />
                <span style="color: #f21000" id="adminCity"></span>
              </div>
              <div
                class="col-xl-2 col-md-2 col-lg-2 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Pin</label>
                <input
                  type="number"
                  v-model="bussiness_form.pin"
                  maxlength="6"
                  class="form-control"
                  placeholder="Pin"
                  style="text-transform: uppercase"
                  @change="lenthheker($event, 'pinCodeBUS')"
                />
                <span style="color: #f21000" id="pinCodeBUS"></span>
                <small style="color: red" v-if="bussiness_form.errors">
                  <span v-if="bussiness_form.errors.error"
                    ><span v-if="bussiness_form.errors.error.pin">{{
                      bussiness_form.errors.error.pin[0]
                    }}</span></span
                  ></small
                >
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <strong>Confirm</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end of the create bussiness modal  -->

  <!-- doccuments Upload  Modal-->

  <div
    class="modal fade"
    id="doccUpload"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel">
            <strong>Upload Doccuments</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger p-1 mt-1" role="alert" v-if="msgImgError">
                {{ msgImgError }}
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-xl-4 col-md-4 col-lg-4col-12"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card shadow-lg rounded" style="width: 100%">
                <img
                  :src="bussinessPanImg"
                  class="card-img-top"
                  alt="..."
                  style="height: 150px"
                />
                <div class="card-body">
                  <label for="panphoto">
                    <img
                      style="width: 25px; height: 25px"
                      src="/assets/image/upload.png"
                    />

                    &nbsp;&nbsp;<b>Pan Card</b>
                  </label>
                  <input
                    @change="uploadPanPhoto"
                    class="input-file"
                    style="position: absolute; top: -150000px"
                    id="panphoto"
                    type="file"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-xl-4 col-md-4 col-lg-4col-12"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card shadow-lg rounded" style="width: 100%">
                <img
                  :src="logoImg"
                  class="card-img-top"
                  alt="..."
                  style="height: 150px"
                />
                <div class="card-body">
                  <label for="logo">
                    <img
                      style="width: 25px; height: 25px"
                      src="/assets/image/upload.png"
                    />

                    &nbsp;&nbsp;<b>Logo</b>
                  </label>
                  <input
                    @change="logoupload"
                    class="input-file"
                    style="position: absolute; top: -150000px"
                    id="logo"
                    type="file"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-xl-4 col-md-4 col-lg-4 col-12"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card shadow-lg rounded" style="width: 100%">
                <img :src="tanImg" class="card-img-top" alt="..." style="height: 150px" />
                <div class="card-body">
                  <label for="tanphoto">
                    <img
                      style="width: 25px; height: 25px"
                      src="/assets/image/upload.png"
                    />

                    &nbsp;&nbsp;<b>Tan </b>
                  </label>
                  <input
                    @change="tanpload"
                    class="input-file"
                    style="position: absolute; top: -150000px"
                    id="tanphoto"
                    type="file"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-xl-4 col-md-4 col-lg-4 col-12"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card shadow-lg rounded" style="width: 100%">
                <img :src="cinImg" class="card-img-top" alt="..." style="height: 150px" />
                <div class="card-body">
                  <label for="cinphoto">
                    <img
                      style="width: 25px; height: 25px"
                      src="/assets/image/upload.png"
                    />

                    &nbsp;&nbsp;<b>Cin </b>
                  </label>
                  <input
                    @change="cinpload"
                    class="input-file"
                    style="position: absolute; top: -150000px"
                    id="cinphoto"
                    type="file"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>

            <div
              class="col-xl-4 col-md-4 col-lg-4 col-12"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card shadow-lg rounded" style="width: 100%">
                <img
                  :src="gstInImg"
                  class="card-img-top"
                  alt="..."
                  style="height: 150px"
                />
                <div class="card-body">
                  <label for="gstinphoto">
                    <img
                      style="width: 25px; height: 25px"
                      src="/assets/image/upload.png"
                    />

                    &nbsp;&nbsp;<b>GstIn </b>
                  </label>
                  <input
                    @change="gstinpload"
                    class="input-file"
                    style="position: absolute; top: -150000px"
                    id="gstinphoto"
                    type="file"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #f21300"
            data-bs-dismiss="modal"
          >
            <strong>Close</strong>
          </button>
          <!-- <button type="button" class="btn btn-sm text-white"
                        style="background-color:#00364f"><strong>Confirm</strong></button> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="bankverify"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel">
            <strong>Verifiy Bank Account</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="form-group">
                <label>Account Holder</label>
                <input
                  v-model="form_bank_verify.accountHolderName"
                  type="text"
                  style="text-transform: uppercase"
                  class="form-control"
                  placeholder="Account Holder Name"
                />
              </div>
            </div>

            <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="form-group">
                <label>Account Number</label>
                <input
                  type="text"
                  v-model="form_bank_verify.accountNo"
                  style="text-transform: uppercase"
                  class="form-control"
                  placeholder="Account Number"
                />
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="form-group">
                <label>Ifsc </label>
                <input
                  v-model="form_bank_verify.ifsc"
                  type="text"
                  class="form-control"
                  style="text-transform: uppercase"
                  placeholder="Ifsc"
                />
              </div>
            </div>
            <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
              <div class="form-group">
                <label>Mobile Number</label>
                <input
                  type="number"
                  maxlength="10"
                  v-model="form_bank_verify.mobile"
                  class="form-control"
                  style="text-transform: uppercase"
                  placeholder="Mobile Number"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #f21300"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <!-- @click="(businessVal == 'false') ? createBussinessSave() : updateBussinessSave()" -->
          <button
            @click="veryfiedBank()"
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #00364f"
          >
            <strong>Confirm</strong>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "SuperAdminClientProfile ",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      msgImgError: "",
      projects: [],
      veryfied: "false",
      exist: "false",
      panNo: "",
      form: new Form({
        photo: "",
        pan: "",
        fname: "",
        lname: "",
        phone: "",
        email: "",
        dob: null,
        gender: "male",
        address: null,
        address2: null,
        alternativePhone: null,
        city: null,
        fatherName: "",
        pin: null,
        state_id: 1,
        code: "",
      }),
      form_bank_verify: {
        accountHolderName: "",
        accountNo: "",
        mobile: "",
        ifsc: "",
      },
      bussiness_id: null,
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      extensionPanPhoto: "",
      extensionLogo: "",
      extensionTan: "",
      extensionCin: "",
      extensionGstIn: "",
      loading: false,
      clientKyc: {},
      client: {},
      coiImg: this.$store.state.placeholderImg,
      profileImg: this.$store.state.placeholderImg,
      logoImg: this.$store.state.placeholderImg,
      tanImg: this.$store.state.placeholderImg,
      bussinessPanImg: this.$store.state.placeholderImg,
      gstInImg: this.$store.state.placeholderImg,
      cinImg: this.$store.state.placeholderImg,
      states: [],
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      passBookImgUrl: "",
      businessAll: [],
      bussiness_form: {
        business_type_id: null,
        client_id: this.$route.params.id,
        name: "",
        email: "",
        phone: "",
        panNo: "",
        tanNo: "",
        gstinNo: "",
        cinNo: "",
        address: "",
        address2: "",
        state_id: null,
        city: "",
        pin: "",

        logo: "",
        panPhoto: "",
        gstinPhoto: "",
        cinPhoto: "",
        tanPhoto: "",
        status: "",
        errors: {},
      },
      businessVal: "false",
      businesstype: [],
      // opened: [],
      form_bank: {
        transaction_id: null,
        business_id: null,
        mobile: "",
        accountHolderName: "",
        bank: "",
        branch: "",
        accountNo: "",
        ifsc: "",
        type: "",
        status: "",
        apiVerifiedBy: "Sand Box",
        remark: "",
      },
      client_id: null,
      panDisabled: "false",
      edit: "false",
      businessInformation: {},
      businessDataClient: {},
      gstinVal: "false",
      demophoneval: "false",
      demophonevalBUS: "false",
      bussinessName: "false",
      pinCode: "false",
      pinCodeBUS: "false",
      adminCityBUS: "false",
      adminCity: "false",
      adminAddress: "false",
      adminAddressBUS: "false",
      doBDateAdd: "false",
    };
  },
  methods: {
    getBallance(client_service) {
      var bal = 0;
      var payments = client_service.client_payment;

if(payments.length !=0){
  console.log('***************')
      console.log(payments)
      for (var i = 0; i < payments.length; i++) {
        bal += payments[i].amt;
      }
      bal = client_service.serviceCharge + client_service.serviceChargeGst - bal;
      return bal;
}else{
  return (client_service.serviceCharge + client_service.serviceChargeGst);
}
     
    },
    dateCheker(event, type) {
      var currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      // console.log(currentDateWithFormat);

      var data = event.target.value;
      // console.log(data);
      // if (type == "doiDate") {
      //   if (currentDateWithFormat < data) {
      //     // console.log(data.length);
      //     this.doiDate = "true";
      //     console.log(type);
      //     $("#doiDate").html("<small>It should be less than current date</small>");
      //   }
      //   if (currentDateWithFormat >= data) {
      //     console.log(data.length);
      //     this.doiDate = "false";
      //     console.log(type);
      //     $("#doiDate").html("<small></small>");
      //   }
      // }

      if (type == "doBDateAdd") {
        if (currentDateWithFormat < data) {
          this.doBDateAdd = "true";
          console.log(type);
          $("#doBDateAdd").html("<small>It should be less than current date</small>");
        }
        if (currentDateWithFormat >= data) {
          this.doBDateAdd = "false";
          console.log(type);
          $("#doBDateAdd").html("<small></small>");
        }
      }
      // }
    },

    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "demophoneval") {
        if (data.length != 10) {
          console.log(data.length);
          this.demophoneval = "true";
          $("#demophoneval").html("<small>Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          console.log(data.length);
          this.demophoneval = "false";
          $("#demophoneval").html("<small></small>");
        }
      }
      if (type == "demophonevalBUS") {
        if (data.length != 10) {
          console.log(data.length);
          this.demophonevalBUS = "true";
          $("#demophonevalBUS").html("<small>Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          console.log(data.length);
          this.demophonevalBUS = "false";
          $("#demophonevalBUS").html("<small></small>");
        }
      }
      if (type == "alt") {
        if (data.length != 10) {
          console.log(data.length);
          this.submitStatus = "true";
          $("#demoAltphoneval").html("<small>Alt Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          console.log(data.length);
          this.submitStatus = "false";
          $("#demoAltphoneval").html("<small></small>");
        }
      }

      if (type == "pinCode") {
        if (data.length != 6) {
          console.log(data.length);
          this.pinCode = "true";
          $("#pinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          console.log(data.length);
          this.pinCode = "false";
          $("#pinCode").html("<small></small>");
        }
      }

      if (type == "pinCodeBUS") {
        if (data.length != 6) {
          console.log(data.length);
          this.pinCodeBUS = "true";
          $("#pinCodeBUS").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          console.log(data.length);
          this.pinCodeBUS = "false";
          $("#pinCodeBUS").html("<small></small>");
        }
      }

      if (type == "gstinVal") {
        if (data.length != 15) {
          console.log(data.length);
          this.gstinVal = "true";
          $("#gstinVal").html("<small>GSTIN 15 Digit Required</small>");
        }
        if (data.length == 15) {
          console.log(data.length);
          this.gstinVal = "false";
          $("#gstinVal").html("<small></small>");
        }
      }

      if (type == "bussinessName") {
        if (data.length < 3) {
          console.log(data.length);
          this.bussinessName = "true";
          $("#bussinessName").html("<small>Required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.bussinessName = "false";
          $("#bussinessName").html("<small></small>");
        }
      }

      if (type == "adminAddress") {
        if (data.length < 3) {
          console.log(data.length);
          this.adminAddress = "true";
          $("#adminAddress").html("<small>Address required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.adminAddress = "false";
          $("#adminAddress").html("<small></small>");
        }
      }

      if (type == "adminAddressBUS") {
        if (data.length < 3) {
          console.log(data.length);
          this.adminAddressBUS = "true";
          $("#adminAddressBUS").html("<small>Address required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.adminAddressBUS = "false";
          $("#adminAddressBUS").html("<small></small>");
        }
      }
      if (type == "adminCity") {
        if (data.length < 3) {
          console.log(data.length);
          this.adminCity = "true";
          $("#adminCity").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.adminCity = "false";
          $("#adminCity").html("<small></small>");
        }
      }

      if (type == "adminCityBUS") {
        if (data.length < 3) {
          console.log(data.length);
          this.adminCityBUS = "true";
          $("#adminCityBUS").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.adminCityBUS = "false";
          $("#adminCityBUS").html("<small></small>");
        }
      }
      if (type == "businessAddress") {
        if (data.length < 3) {
          console.log(data.length);
          this.businessAddress = "true";
          $("#businessAddress").html("<small>Address required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.businessAddress = "false";
          $("#businessAddress").html("<small></small>");
        }
      }

      if (type == "bussinessCity") {
        if (data.length < 3) {
          console.log(data.length);
          this.bussinessCity = "true";
          $("#bussinessCity").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.bussinessCity = "false";
          $("#bussinessCity").html("<small></small>");
        }
      }

      if (type == "scretKeyLenth") {
        if (data.length < 10) {
          console.log(data.length);
          this.submitStatus = "true";
          $("#scretKeyLenth").html(
            "<small>Secret Key required minimum 10 letters</small>"
          );
        }
        if (data.length >= 10) {
          console.log(data.length);
          this.submitStatus = "false";
          $("#scretKeyLenth").html("<small></small>");
        }
      }
    },
    openWindow(url) {
      window.open(`${url}`);
    },
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm a");
      }
      
    },
    valuenullOfModalPanDissable() {
      this.panNo = "";
      this.bussiness_form.panNo = "";
      this.panDisabled = "false";
      this.bussiness_form = {};
      this.veryfied = "false";
    },
    logoReturn(bussiness) {
      var img;
      if (bussiness.logo != null) {
        if (
          this.$store.state.imgUrl +
          "/business/" +
          bussiness.id +
          "/" +
          bussiness.logo
        ) {
          img =
            this.$store.state.imgUrl + "/business/" + bussiness.id + "/" + bussiness.logo;
          return img;
        }
        if (
          this.$store.state.imgUrl +
          "/business/" +
          bussiness.id +
          "/thumbs/" +
          bussiness.logo
        ) {
          img =
            this.$store.state.imgUrl +
            "/business/" +
            bussiness.id +
            "/thumbs/" +
            bussiness.logo;
          return img;
        }
        // this.bussiness_form.logo = this.logoImg
        this.extensionLogo = img.split(".").pop();

        if (this.extensionLogo == "pdf") {
          img = "/assets/image/pdf.png";
          return img;
        }
      } else {
        img = this.$store.state.placeholderImg;
        return img;
      }
    },
    getBusinessInformation(businessInformation, business) {
      this.businessInformation = businessInformation;
      this.businessDataClient = business;
    },
    valusigetByClick(bussiness) {
      this.form = {};
      this.panNo = "";
      this.panDisabled = "false";
      // this.loadBussiness();
      // console.log('-----------------')
      // console.log(bussiness)
      this.bussiness_id = bussiness.id;
      this.form.state_id = 1;
      this.form.gender = "male";
      $("#demophoneval").html("<small></small>");
      $("#pinCode").html("<small></small>");
      $("#adminCity").html("<small></small>");
      $("#adminAddress").html("<small></small>");
      // console.log(this.bussiness_id)
    },
    businessNull() {
      $("#bussinessName").html("<small></small>");
      $("#demophonevalBUS").html("<small></small>");
      $("#pinCodeBUS").html("<small></small>");
      $("#adminCity").html("<small></small>");
      $("#adminAddressBUS").html("<small></small>");
      this.businessVal = "false";
    },
    valueNull() {
      this.panNo = "";
      this.form.pan = "";
      this.form.fname = "";
      this.form.lname = "";
      this.form.phone = "";
      this.form.email = "";
      this.form.dob = "";
      this.form.gender = "male";
      this.form.address = "";
      this.form.address2 = "";
      this.form.alternativePhone = "";
      this.form.city = "";
      this.form.fatherName = "";
      this.form.pin = "";
      this.form.state_id = 1;
      this.form.code = "";

      this.panDisabled = "false";
      this.veryfied = "false";

      $("#demophoneval").html("<small></small>");
      $("#pinCode").html("<small></small>");
      $("#adminCity").html("<small></small>");
      $("#adminAddress").html("<small></small>");
    },
    addNewClient() {
      if (
        this.adminAddress == "false" &&
        this.adminCity == "false" &&
        this.demophoneval == "false" &&
        this.pinCode == "false"
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        formData.append("business_id", this.bussiness_id);
        // this.loading = true
        this.$axios
          .post(`superadmin/client`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data;
            // console.log(res.data)
            this.$swal.fire({
              position: "top-end",
              icon: "success",
              title: "The Client Created Successfully.",
              showConfirmButton: false,
              timer: 1500,
            });
            $("#staticBackdrop").modal("hide");
            this.valueNull();
            this.loadClients();
            this.loadBussiness();
          });
      }

      // .finally(() => (this.loading = false))
    },
    updateClient() {
      if (
        this.adminAddress == "false" &&
        this.adminCity == "false" &&
        this.demophoneval == "false" &&
        this.pinCode == "false"
      ) {
        const formData = new FormData();
        // const keys = Object.keys(this.form)
        // for (let n = 0; n < keys.length; n += 1) {
        //     if (this.form[keys[n]] !== null) {
        //         formData.append(keys[n], this.form[keys[n]])
        //     }
        // }
        formData.append("business_id", this.bussiness_id);
        formData.append("client_id", this.client_id);
        this.$axios
          .post(`superadmin/businessclient`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data;
            // console.log(res.data)
            this.$swal.fire({
              position: "top-end",
              icon: "success",
              title: "The User Added Succes Fully successfully.",
              showConfirmButton: false,
              timer: 1500,
            });
            $("#staticBackdrop").modal("hide");
            // this.form.reset()
            this.valueNull();
            this.loadClients();
            this.loadBussiness();
          });
        // .finally(() => (this.loading = false))
      }
      // this.loading = true
    },
    findRecordeByPanId() {
      this.$axios
        .get(`superadmin/client?pan=${this.panNo}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.record = res.data.data.data[0];
          if (res.data.data.data[0]) {
            // alert(this.record.pan)
            this.form = this.record;
            this.exist = "true";
            this.veryfied = "false";
            this.panDisabled = "true";
            this.client_id = this.record.id;
            this.edit = "true";
            this.veryfied = "false";
          } else {
            // alert('by')
            this.$axios
              .post(
                "superadmin/verifypan",
                { panNo: this.panNo },
                { headers: { Authorization: "Bearer " + localStorage.accessToken } }
              )
              .then((res) => {
                // console.log(res.data.data)
                if (res.data.data.data) {
                  var result = res.data.data.data;
                  // console.log('-----Verrifiyed--- in else--')
                  this.$swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Pan Verrifiyed  Succesfully",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.panDisabled = "true";
                  this.veryfied = "false";
                  this.form.pan = this.panNo;
                  this.form.fname = result.first_name;
                  this.form.lname = result.last_name;
                  if (result.middle_name) {
                    this.form.lname = result.middle_name + " " + result.last_name;
                  }
                } else {
                  // console.log('-----invalid pan--')
                  this.veryfied = "invalid";
                  this.exist = "false";
                }
              });
            // this.form.pan = this.panNo

            // $('#panVerifiy').modal('hide')
            // $('#staticBackdrop').modal('show')
          }
        });

      // this.panNo = ''
    },

    veryfiedBank() {
      this.$axios
        .post(`superadmin/verifybankaccount`, this.form_bank_verify, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data.data)
          let result = res.data.data;
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Bank Account verifyed Succesfully",
            showConfirmButton: false,
            timer: 1500,
          });
          if (result.data) {
            this.form_bank.transaction_id = result.transaction_id;
            this.form_bank.accountHolderName = this.form_bank_verify.accountHolderName;
            this.form_bank.accountNo = this.form_bank_verify.accountNo;
            this.form_bank.mobile = this.form_bank_verify.mobile;
            this.form_bank.ifsc = this.form_bank_verify.ifsc;
            $("#addBank").modal("show");
            $("#bankverify").modal("hide");
          } else {
            // console.log('Invaldi Details')
          }
        });
    },
    valueForBankAccount(business) {
      this.bussiness_id = business.id;
      this.form_bank.business_id = business.id;
      // alert(business.id)
      $("#bankverify").modal("show");
    },
    createBankForBussiness() {
      this.$axios
        .post(`superadmin/businessbankaccount`, this.form_bank, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res.data;
          // console.log(res.data.data)
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Bank Account Created Succesfully",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    assignBussinessId(bussiness) {
      this.bussiness_id = bussiness.id;
      if (bussiness.logo != null) {
        if (
          this.$store.state.imgUrl +
          "/business/" +
          this.bussiness_id +
          "/" +
          bussiness.logo
        ) {
          this.logoImg =
            this.$store.state.imgUrl +
            "/business/" +
            this.bussiness_id +
            "/" +
            bussiness.logo;
        }
        if (
          this.$store.state.imgUrl +
          "/business/" +
          this.bussiness_id +
          "/thumbs/" +
          bussiness.logo
        ) {
          this.logoImg =
            this.$store.state.imgUrl +
            "/business/" +
            this.bussiness_id +
            "/thumbs/" +
            bussiness.logo;
        }
        this.bussiness_form.logo = this.logoImg;
        this.extensionLogo = this.logoImg.split(".").pop();

        if (this.extensionLogo == "pdf") {
          this.logoImg = "/assets/image/pdf.png";
        }
      } else {
        this.logoImg = this.$store.state.placeholderImg;
      }

      if (bussiness.panPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/business/" +
          this.bussiness_id +
          "/" +
          bussiness.panPhoto
        ) {
          this.bussinessPanImg =
            this.$store.state.imgUrl +
            "/business/" +
            this.bussiness_id +
            "/" +
            bussiness.panPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/business/" +
          this.bussiness_id +
          "/thumbs/" +
          bussiness.panPhoto
        ) {
          this.bussinessPanImg =
            this.$store.state.imgUrl +
            "/business/" +
            this.bussiness_id +
            "/thumbs/" +
            bussiness.panPhoto;
        }
        this.bussiness_form.panPhoto = this.bussinessPanImg;
        this.extensionPanPhoto = this.bussinessPanImg.split(".").pop();

        if (this.extensionPanPhoto == "pdf") {
          this.bussinessPanImg = "/assets/image/pdf.png";
        }
      } else {
        this.bussinessPanImg = this.$store.state.placeholderImg;
      }

      if (bussiness.tanPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/business/" +
          this.bussiness_id +
          "/" +
          bussiness.tanPhoto
        ) {
          this.tanImg =
            this.$store.state.imgUrl +
            "/business/" +
            this.bussiness_id +
            "/" +
            bussiness.tanPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/business/" +
          this.bussiness_id +
          "/thumbs/" +
          bussiness.tanPhoto
        ) {
          this.tanImg =
            this.$store.state.imgUrl +
            "/business/" +
            this.bussiness_id +
            "/thumbs/" +
            bussiness.tanPhoto;
        }
        this.bussiness_form.tanPhoto = this.tanImg;
        this.extensionTan = this.tanImg.split(".").pop();

        if (this.extensionTan == "pdf") {
          this.tanImg = "/assets/image/pdf.png";
        }
      } else {
        this.tanImg = this.$store.state.placeholderImg;
      }

      if (bussiness.cinPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/business/" +
          this.bussiness_id +
          "/" +
          bussiness.cinPhoto
        ) {
          this.cinImg =
            this.$store.state.imgUrl +
            "/business/" +
            this.bussiness_id +
            "/" +
            bussiness.cinPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/business/" +
          this.bussiness_id +
          "/thumbs/" +
          bussiness.cinPhoto
        ) {
          this.cinImg =
            this.$store.state.imgUrl +
            "/business/" +
            this.bussiness_id +
            "/thumbs/" +
            bussiness.cinPhoto;
        }
        this.bussiness_form.cinPhoto = this.cinImg;
        this.extensionCin = this.cinImg.split(".").pop();

        if (this.extensionCin == "pdf") {
          this.cinImg = "/assets/image/pdf.png";
        }
      } else {
        this.cinImg = this.$store.state.placeholderImg;
      }

      if (bussiness.gstinPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/business/" +
          this.bussiness_id +
          "/" +
          bussiness.gstinPhoto
        ) {
          this.gstInImg =
            this.$store.state.imgUrl +
            "/business/" +
            this.bussiness_id +
            "/" +
            bussiness.gstinPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/business/" +
          this.bussiness_id +
          "/thumbs/" +
          bussiness.gstinPhoto
        ) {
          this.gstInImg =
            this.$store.state.imgUrl +
            "/business/" +
            this.bussiness_id +
            "/thumbs/" +
            bussiness.gstinPhoto;
        }
        this.bussiness_form.gstinPhoto = this.gstInImg;
        this.extensionCin = this.gstInImg.split(".").pop();

        if (this.extensionCin == "pdf") {
          this.gstInImg = "/assets/image/pdf.png";
        }
      } else {
        this.gstInImg = this.$store.state.placeholderImg;
      }
    },
    uploadDoccuments() {
      const formData = new FormData();
      const keys = Object.keys(this.bussiness_form);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.bussiness_form[keys[n]] !== null) {
          formData.append(keys[n], this.bussiness_form[keys[n]]);
        }
      }
      // this.loading = true
      this.$axios
        .post(`superadmin/business/document/${this.bussiness_id}/upload`, formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res.data;
          // console.log(res.data.data)
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Upload Succesfully",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      // .finally(() => (this.loading = false))
    },
    uploadPanPhoto(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "TAN File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#panphoto").value;
        this.extensionPanPhoto = fileName.split(".").pop();

        if (
          this.extensionPanPhoto == "pdf" ||
          this.extensionPanPhoto == "jpg" ||
          this.extensionPanPhoto == "jpeg" ||
          this.extensionPanPhoto == "png"
        ) {
          this.bussinessPanImg = URL.createObjectURL(e.target.files["0"]);
          this.bussiness_form.panPhoto = e.target.files["0"];
          this.uploadDoccuments();
        } else {
          this.msgImgError = "File type must JPG ,JPEG , PNG , PDF ";
        }

        if (this.extensionPanPhoto == "pdf") {
          this.bussinessPanImg = "/assets/image/pdf.png";
        }
      }
    },
    logoupload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "TAN File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#logo").value;
        this.extensionLogo = fileName.split(".").pop();

        if (
          this.extensionLogo == "jpg" ||
          this.extensionLogo == "jpeg" ||
          this.extensionLogo == "png"
        ) {
          this.logoImg = URL.createObjectURL(e.target.files["0"]);
          this.bussiness_form.logo = e.target.files["0"];
          this.uploadDoccuments();
        } else {
          this.msgImgError = "File type must JPG ,JPEG , PNG ";
        }
      }
    },
    tanpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "TAN File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#tanphoto").value;
        this.extensionTan = fileName.split(".").pop();

        if (
          this.extensionTan == "pdf" ||
          this.extensionTan == "jpg" ||
          this.extensionTan == "jpeg" ||
          this.extensionTan == "png"
        ) {
          this.tanImg = URL.createObjectURL(e.target.files["0"]);
          this.bussiness_form.tanPhoto = e.target.files["0"];
          this.uploadDoccuments();
        } else {
          this.msgImgError = "File type must JPG ,JPEG , PNG , PDF ";
        }

        if (this.extensionTan == "pdf") {
          this.tanImg = "/assets/image/pdf.png";
        }
      }
    },
    gstinpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "GSTIN File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#gstinphoto").value;
        this.extensionGstIn = fileName.split(".").pop();

        if (
          this.extensionGstIn == "pdf" ||
          this.extensionGstIn == "jpg" ||
          this.extensionGstIn == "jpeg" ||
          this.extensionGstIn == "png"
        ) {
          this.gstInImg = URL.createObjectURL(e.target.files["0"]);
          this.bussiness_form.gstinPhoto = e.target.files["0"];
          this.uploadDoccuments();
        } else {
          this.msgImgError = "File type must JPG ,JPEG , PNG , PDF ";
        }

        if (this.extensionGstIn == "pdf") {
          this.gstInImg = "/assets/image/pdf.png";
        }
      }
    },
    cinpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Cin File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#cinphoto").value;
        this.extensionCin = fileName.split(".").pop();

        if (
          this.extensionCin == "pdf" ||
          this.extensionCin == "jpg" ||
          this.extensionCin == "jpeg" ||
          this.extensionCin == "png"
        ) {
          this.cinImg = URL.createObjectURL(e.target.files["0"]);
          this.bussiness_form.cinPhoto = e.target.files["0"];
          this.uploadDoccuments();
        } else {
          this.msgImgError = "File type must JPG ,JPEG , PNG , PDF ";
        }

        if (this.extensionCin == "pdf") {
          this.cinImg = "/assets/image/pdf.png";
        }
      }
    },
    valueAssignBussiness(business) {
      this.bussiness_form = {};
      this.panDisabled = "false";
      this.loadBussiness();

      console.log(business);
      this.panNo = business.panNo;
      this.bussiness_form.panNo = business.panNo;
      this.bussiness_form = business;
      this.panDisabled = "true";
      this.businessVal = "true";
      // console.log(this.bussiness_form)
    },
    loadBussiness() {
      this.$axios
        .get(`superadmin/businessclient?client_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.businessAll = res.data.data.data;
          // console.log('----------business client-----------------')
          // console.log(this.businessAll)
          // console.log('---------------------------')
        });
    },
    loadBussinessType() {
      // this.loading = true
      this.$axios
        .get(`superadmin/businesstype`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data.data.data)
          this.businesstype = res.data.data.data;
        });
      // .finally(() => (this.loading = false))
    },
    updateBussinessSave() {
      // this.loading = true
      // gstinVal: "false",
      // demophoneval: "false",
      // bussinessName: "false",
      // pinCode: "false",
      // adminCity: "false",
      // adminAddress: "false",
      if (
        this.gstinVal == "false" &&
        this.demophoneval == "false" &&
        this.bussinessName == "false" &&
        this.pinCode == "false" &&
        this.adminCity == "false" &&
        this.adminAddress == "false"
      ) {
        this.$axios
          .put(`superadmin/business/${this.bussiness_form.id}`, this.bussiness_form, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data;
            // console.log(res.data)
            this.$swal.fire({
              position: "top-end",
              icon: "success",
              title: "Business Updated Succesfully",
              showConfirmButton: false,
              timer: 1500,
            });

            $("#createBussiness").modal("hide");
            this.bussiness_form = {};
            this.panDisabled = "false";

            this.loadBussiness();
          })
          .catch((error) => {
            this.bussiness_form.errors = error.response.data;
          });
      }

      // });
      // .finally(() => (this.loading = false))
    },
    createBussinessSave() {
      if (
        this.gstinVal == "false" &&
        this.demophoneval == "false" &&
        this.bussinessName == "false" &&
        this.pinCode == "false" &&
        this.adminCity == "false" &&
        this.adminAddress == "false"
      ) {
        this.loading = true;
        const formData = new FormData();
        const keys = Object.keys(this.bussiness_form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.bussiness_form[keys[n]] !== null) {
            formData.append(keys[n], this.bussiness_form[keys[n]]);
          }
        }
        // formData.append('business_id', this.bussiness_id)
        formData.append("client_id", this.$route.params.id);
        this.$axios
          .post(`superadmin/business`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data;
            // console.log(res.data)
            this.$swal.fire({
              position: "top-end",
              icon: "success",
              title: "Business Created Succesfully",
              showConfirmButton: false,
              timer: 1500,
            });
            $("#createBussiness").modal("hide");
            this.bussiness_form = {};
            this.panDisabled = "false";

            this.loadBussiness();
          })
          .catch((error) => {
            this.bussiness_form.errors = error.response.data;
          })
          .finally(() => (this.loading = false));
      }
    },

    deleteBussiness(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            // this.loading = true
            this.$axios
              .delete(`superadmin/business/${id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res.data;
                // console.log(res.data)
                this.$swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "Business Deleted Succesfully",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.loadBussiness();
              })
              // .finally(() => (this.loading = false))
              .catch((error) => {
                console.log(error);
              });
          }
        });
    },
    businessPanVeryfied() {
      this.$axios
        .get(`superadmin/client?pan=${this.panNo}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.record = res.data.data.data[0];
          if (res.data.data.data[0]) {
            // alert(this.record.pan)

            this.bussiness_form = this.record;
            this.bussiness_form.panNo = this.panNo;
            this.panNo = "";
            this.exist = "true";
            this.veryfied = "false";
            this.panDisabled = "true";
            // this.client_id = this.record.id
            // this.edit = 'true'
            this.veryfied = "false";
          } else {
            // alert('by')
            this.$axios
              .post(
                "superadmin/verifypan",
                { panNo: this.panNo },
                { headers: { Authorization: "Bearer " + localStorage.accessToken } }
              )
              .then((res) => {
                console.log(res.data.data.data);
                if (res.data.data.data.status == "VALID") {
                  var result = res.data.data.data;
                  // console.log('-----Verrifiyed--- in else--')
                  this.$swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Pan Verrifiyed  Succesfully",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.panDisabled = "true";
                  this.veryfied = "false";
                  this.bussiness_form.panNo = this.panNo;
                  this.panNo = "";
                  // this.form.fname = result.first_name;
                  // this.form.lname = result.last_name;
                  this.bussiness_form.name = result.full_name;
                  if (result.middle_name) {
                    this.form.lname = result.middle_name + " " + result.last_name;
                  }
                } else {
                  // console.log('-----invalid pan--')
                  this.veryfied = "invalid";
                  this.exist = "false";
                }
              });
            // this.form.pan = this.panNo

            // $('#panVerifiy').modal('hide')
            // $('#staticBackdrop').modal('show')
          }
        });

      // this.panNo = ''
    },
    loadStates() {
      this.$axios
        .get(`superadmin/state?all=all`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.states = res.data.data;
        });
    },
    loadClients() {
      // this.loading = true
      this.$axios
        .get(`superadmin/client/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.client = res.data.data;
          // console.log('---------------------client---------------------------')
          // console.log(this.client)
          if (this.client.photo != null) {
            this.profileImg =
              this.$store.state.imgUrl +
              "/client/" +
              this.client.id +
              "/thumbs/" +
              res.data.data.photo;
          } else {
            this.profileImg = this.$store.state.placeholderImg;
          }
          // console.log('---------------------client---------------------------')







          if (this.client.client_kyc.aadharFrontPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/client/" +
              this.$route.params.id +
              "/thumbs/" +
              this.client.client_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/client/" +
                this.$route.params.id +
                "/thumbs/" +
                this.client.client_kyc.aadharFrontPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/client/" +
              this.$route.params.id +
              "/" +
              this.client.client_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/client/" +
                this.$route.params.id +
                "/" +
                this.client.client_kyc.aadharFrontPhoto;
            }

            this.adharFrontImgPdf = this.adharFrontImg;
            this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

            if (this.extensionkycAdharFront == "pdf") {
              this.adharFrontImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharFrontImg = this.$store.state.placeholderImg;
          }

          if (this.client.client_kyc.aadharBackPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/client/" +
              this.$route.params.id +
              "/thumbs/" +
              this.client.client_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/client/" +
                this.$route.params.id +
                "/thumbs/" +
                this.client.client_kyc.aadharBackPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/client/" +
              this.$route.params.id +
              "/" +
              this.client.client_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/client/" +
                this.$route.params.id +
                "/" +
                this.client.client_kyc.aadharBackPhoto;
            }

            this.adharBackImgPdf = this.adharBackImg;
            this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

            if (this.extensionkycAdharBack == "pdf") {
              this.adharBackImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharBackImg = this.$store.state.placeholderImg;
          }

          if (this.client.client_kyc.panPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/client/" +
              this.$route.params.id +
              "/thumbs/" +
              this.client.client_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/client/" +
                this.$route.params.id +
                "/thumbs/" +
                this.client.client_kyc.panPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/client/" +
              this.$route.params.id +
              "/" +
              this.client.client_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/client/" +
                this.$route.params.id +
                "/" +
                this.client.client_kyc.panPhoto;
            }

            this.panImgPdf = this.panImg;
            this.extensionkycPanPhoto = this.panImg.split(".").pop();

            if (this.extensionkycPanPhoto == "pdf") {
              this.panImg = "/assets/image/pdf.png";
            }
          } else {
            this.panImg = this.$store.state.placeholderImg;
          }
        });
      // .finally(() => (this.loading = false))
    },
  
    loadProjects() {
      this.$axios
        .get(`superadmin/clientservice?client_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data.data)
          this.projects = res.data.data.data;

        });
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.clientView
    console.log(this.$store.state.superAdminPageTitles.clientView)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadProjects();
      this.loadBussinessType();
      this.loadStates();
      this.loadBussiness();
      this.loadClients();
      this.loadPageTitle()
  
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
  text-transform: capitalize;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 10px;
}

.form-check-input:checked {
  background-color: #ffffff;
  border-color: #e70c0c;
  border-width: 3px;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.table.table-sm th,
.table.table-sm td {
  padding: -19.7rem 5rem;
}
</style>
